import Tabs, { TabType } from 'components/Tabs'
import TruckMenu from 'components/pages/TruckMenuPage/TruckMenu'
import React, { useState } from 'react'
import sortBy from 'lodash/sortBy'
import {
  TruckMenusQuery,
  useTruckMenusQuery
} from 'components/TruckMenus/types/TruckMenusQuery'
import ButtonLink from 'components/ui/ButtonLink'
import pathFromUrl from 'utils/pathFromUrl'

type Props = {
  truckId: number
  itemIdsParam?: string
  menuIdParam?: string
  showLinkToSchedule?: boolean
}
const TruckMenus = ({
  truckId,
  itemIdsParam,
  menuIdParam,
  showLinkToSchedule
}: Props) => {
  const [currentTab, setCurrentTab] = useState<TabType>()

  const { data } = useTruckMenusQuery({
    variables: { id: truckId }
  })

  const truck = data?.truck
  if (!truck) return null

  const itemIds: number[] | null = itemIdsParam
    ? itemIdsParam.split(',').map((i) => parseInt(i, 10))
    : null

  let activePublicMenus
  if (menuIdParam) {
    activePublicMenus = data.truck.menus.filter(
      (m) => m.id === parseInt(menuIdParam, 10)
    )
  } else {
    activePublicMenus = data.truck.menus.filter(
      (m) => m.active && m.public && m.items.length > 0
    )
  }

  const makeMenuTabs = ({
    menus
  }: {
    menus: NonNullable<TruckMenusQuery>['truck']['menus']
  }) => {
    const menuTabs = menus.map((menu) => ({
      name: menu.name === 'Default' ? 'Main Menu' : menu.name,
      value: menu.id
    }))
    return sortBy(menuTabs, (t) => t.value)
  }
  const menuTabs = makeMenuTabs({ menus: activePublicMenus })

  const menu = activePublicMenus.find(
    (m) => m.id === (currentTab || menuTabs[0]).value
  )
  if (!menu) return null

  const filterItems = (
    items: NonNullable<TruckMenusQuery>['truck']['menus'][0]['items']
  ): NonNullable<TruckMenusQuery>['truck']['menus'][0]['items'] => {
    if (itemIds) {
      return items.filter((item) => item.active && itemIds.includes(item.id))
    }
    return items.filter((item) => item.active)
  }

  return (
    <div className="max-w-screen-lg mx-auto mt-10" id="menu">
      <p className="text-3xl text-center font-bold mb-6">{`${truck.name} Menu`}</p>
      {activePublicMenus.length > 1 && (
        <div>
          <Tabs
            color="primary"
            currentTab={currentTab || menuTabs[0]}
            setCurrentTab={setCurrentTab}
            size="medium"
            tabs={menuTabs}
            variant="contained"
          />
        </div>
      )}
      <TruckMenu
        items={filterItems(menu.items)}
        primaryUpsellTypes={truck.primaryUpsellTypes}
        showPrices={menu.hasPrices}
        taxIncluded={menu.taxIncluded}
        upsellTypes={data.upsellTypes}
      />
      {showLinkToSchedule && (
        <div className="my-10 flex justify-center">
          <ButtonLink
            color="primary"
            href={pathFromUrl(truck.scheduleUrl)}
            size="large"
            variant="contained"
            shadow
          >
            View Schedule
          </ButtonLink>
        </div>
      )}
    </div>
  )
}

export default TruckMenus
