import React from 'react'
import cn from 'classnames'

const TruckDescription = ({
  name,
  description,
  isGray = true
}: {
  name: string
  description: string
  isGray?: boolean
}) => (
  <div className={cn('pt-10 pb-16 px-4 w-full', { 'bg-gray-50': isGray })}>
    <div className="mx-auto md:max-w-screen-md lg:max-w-screen-lg max-w-screen-sm">
      <h2 className="text-3xl tracking-wide my-8 font-semibold text-center">
        {`About ${name}`}
      </h2>
      <div
        className="prose max-w-none"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  </div>
)

export default TruckDescription
