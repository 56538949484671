import { gql } from '@apollo/client'
import {
  truckRatingsFragment_ratingInfo,
  truckRatingsFragment_ratingInfo_ratings
} from 'components/pages/TruckPage/TruckRatings/types/truckRatingsFragment'
import { StarIcon } from '@heroicons/react/outline'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import stripTimeZone from 'utils/stripTimeZone'
import cn from 'classnames'
import StarsRating from 'components/StarsRating'
import React from 'react'

export const truckRatingsFragment = gql`
  fragment truckRatingsFragment on Truck {
    id
    ratingInfo {
      averageRating
      ratingsCount
      ratings {
        id
        name
        stars
        text
        ratedAt
      }
    }
  }
`

const Review = ({
  rating
}: {
  rating: truckRatingsFragment_ratingInfo_ratings
}) => (
  <div className="flex md:w-1/2 md:mb-12 mb-8 md:pr-12 pr-4">
    <div>
      <h4 className="text-lg font-bold flex flex-row items-center">
        <span className="mr-2">{rating.name}</span>
        <StarsRating scale={2} stars={rating.stars} />
      </h4>
      <p className="text-gray-500 text-sm font-light">
        {format(parseISO(stripTimeZone(rating.ratedAt)), 'MMMM y')}
      </p>
      <p className="mt-1">{rating.text}</p>
    </div>
  </div>
)

const TruckRatings = ({
  ratingInfo,
  isGray = true
}: {
  ratingInfo: truckRatingsFragment_ratingInfo
  isGray?: boolean
}) => {
  if (!ratingInfo.ratings.length) return null

  return (
    <div className={cn('pt-2 pb-12 px-4 w-full', { 'bg-gray-50': isGray })}>
      <div
        className={cn(
          'px-4 md:p-0 md:max-w-screen-md lg:max-w-screen-lg max-w-screen-sm mx-auto'
        )}
        id="ratings"
        style={{ scrollMarginTop: '64px' }}
      >
        <div className="flex items-center">
          <StarIcon className="w-7 h-7 text-orange-400 mr-2" fill="orange" />
          <h3 className="text-2xl tracking-wide my-10 font-semibold">
            {ratingInfo.averageRating} ({ratingInfo.ratingsCount} reviews)
          </h3>
        </div>
        <div className="flex flex-col md:flex-row flex-wrap">
          {ratingInfo.ratings.map((rating) => (
            <Review key={rating.id} rating={rating} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default TruckRatings
