import React from 'react'
import MenuItemsList from 'components/pages/TruckMenuPage/MenuItems'
import { TruckMenusQuery } from 'components/TruckMenus/types/TruckMenusQuery'

const TruckMenu = ({
  items,
  upsellTypes,
  primaryUpsellTypes,
  showPrices,
  taxIncluded
}: {
  items: NonNullable<TruckMenusQuery>['truck']['menus'][0]['items']
  upsellTypes: {
    id: number
    name: string
  }[]
  primaryUpsellTypes: {
    id: number
    name: string
  }[]
  showPrices: boolean
  taxIncluded: boolean
}) => {
  return (
    <div className="flex flex-wrap mb-10">
      <MenuItemsList
        items={items}
        primaryUpsellTypes={primaryUpsellTypes}
        showPrices={showPrices}
        taxIncluded={taxIncluded}
        upsellTypes={upsellTypes}
      />
    </div>
  )
}

export default TruckMenu
