import cn from 'classnames'
import { useRouter } from 'next/router'

type PossibleValues = Date | string | number

export type TabType<T = PossibleValues> = {
  name: string
  value: T
  link?: string
  badge?: string | null
}

type Props<T = PossibleValues> = {
  tabs: TabType<T>[]
  currentTab: TabType<T>
  setCurrentTab: (tab: TabType<T>) => void
  size?: 'large' | 'medium'
  color: 'primary' | 'secondary'
  variant: 'contained' | 'outlined'
  fullWidth?: boolean
  bordered?: boolean
  shrinkOnMobile?: boolean
  additionalButton?: React.ReactNode
}

export default function Tabs<T = PossibleValues>({
  tabs,
  currentTab,
  setCurrentTab,
  size = 'medium',
  color = 'primary',
  variant = 'outlined',
  shrinkOnMobile = true,
  fullWidth = false,
  bordered = false,
  additionalButton
}: Props<T>) {
  const router = useRouter()

  const onTabClick = (tab: TabType<T>) => {
    if (tab.link) {
      router.push(tab.link)
    } else {
      setCurrentTab(tab)
    }
  }

  return (
    <div>
      <div
        className={cn('my-4 mx-4', {
          hidden: !shrinkOnMobile,
          'sm:hidden': shrinkOnMobile
        })}
      >
        <select
          className="block w-full focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
          onChange={(e) => {
            const newTab = tabs.find((tab) => tab.name === e.target.value)
            if (newTab) {
              setCurrentTab(newTab)
            }
          }}
          value={currentTab.name}
        >
          {tabs.map((tab, i) => (
            <option key={(i + tab.name).toString()} value={tab.name}>
              {tab.name} {tab.badge ? `(${tab.badge})` : ''}
            </option>
          ))}
        </select>
      </div>
      <div
        className={cn({
          'hidden sm:block': shrinkOnMobile,
          block: !shrinkOnMobile,
          'border-solid border-t border-b border-gray-200': bordered
        })}
      >
        <nav
          className={cn(
            'relative z-0 flex flex-row max-w-full overflow-y-auto sm:px-4',
            {
              'bg-white w-full': fullWidth,
              'max-w-screen-md lg:max-w-7xl mx-auto': !fullWidth
            }
          )}
        >
          {tabs.map((tab, tabIdx) => {
            const selected = tab.value === currentTab.value
            const isOutlined = variant === 'outlined'
            const isContained = variant === 'contained'
            return (
              <div
                key={(tabIdx + tab.name).toString()}
                className={cn(
                  'group relative min-w-0 overflow-hidden py-4 px-4 text-sm font-medium text-center focus:z-10 cursor-pointer duration-200 ring-orange-400',
                  {
                    'flex-1': fullWidth,
                    'border-b-2': isOutlined,
                    'bg-white': !selected,
                    'text-gray-900 border-solid border-orange-500 bg-orange-100':
                      selected && isOutlined,
                    'text-gray-600 hover:text-gray-700 hover:border-solid hover:border-gray-300 border-transparent':
                      tab.value !== currentTab.value && isOutlined,
                    'px-3 py-2 font-medium text-sm rounded-md hover:text-orange-700': isContained,
                    'bg-orange-100 text-orange-700':
                      selected && isContained && color === 'primary',
                    'text-gray-600 hover:text-gray-700':
                      tab.value !== currentTab.value && isContained,
                    'bg-gray-100 text-gray-700':
                      selected && isContained && color === 'secondary'
                  }
                )}
                onClick={() => onTabClick(tab)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    return onTabClick(tab)
                  }
                  return null
                }}
                role="button"
                style={{ minWidth: 'fit-content' }}
                tabIndex={0}
              >
                <span
                  className={cn(
                    size === 'large' && 'text-lg',
                    size === 'medium' && 'text-base'
                  )}
                >
                  {tab.name}
                  {tab.badge ? (
                    <span
                      className={cn(
                        selected
                          ? 'bg-orange-200 text-orange-600'
                          : 'bg-orange-100',
                        'ml-3 py-0.5 px-2.5 rounded-full',
                        {
                          'text-sm': size === 'medium',
                          'text-base': size === 'large'
                        }
                      )}
                    >
                      {tab.badge}
                    </span>
                  ) : null}
                </span>
              </div>
            )
          })}
          {additionalButton && (
            <div className="py-4 px-4 font-bold flex justify-center items-center focus:z-10 cursor-pointer">
              {additionalButton}
            </div>
          )}
        </nav>
      </div>
    </div>
  )
}
