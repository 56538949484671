/* tslint:disable */
/* eslint-disable */
// @ts-ignore
import * as Types from '../../../global-graphql-types'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import * as ApolloReactHooks from 'utils/extendApolloHooks'
const defaultOptions = {} as const
export type TruckMenusQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']
}>

export type TruckMenusQuery = {
  __typename: 'Query'
  truck: {
    __typename: 'Truck'
    id: number
    name: string
    scheduleUrl: string
    primaryUpsellTypes: Array<{
      __typename: 'UpsellType'
      id: number
      name: string
      position: number
    }>
    menus: Array<{
      __typename: 'Menu'
      id: number
      name: string
      active: boolean
      public: boolean
      hasPrices: boolean
      taxIncluded: boolean
      catering: boolean
      items: Array<{
        __typename: 'Item'
        id: number
        name: string
        description: string | null
        active: boolean
        image: { __typename: 'ItemImage'; medium: string; present: boolean }
        upsellType: {
          __typename: 'UpsellType'
          id: number
          name: string
          position: number
        }
        price: { __typename: 'Money'; formatted: string; cents: number }
        tags: Array<{
          __typename: 'ItemTag'
          id: string
          highlighted: boolean
          imageSvgUrl: string | null
          name: string
        }>
        itemQuestions: Array<{
          __typename: 'ItemQuestion'
          id: number
          active: boolean | null
          text: string
          itemAnswers: Array<{
            __typename: 'ItemAnswer'
            id: number
            active: boolean | null
            text: string | null
            adjustment: { __typename: 'Money'; formatted: string } | null
          }> | null
        }>
      }>
      foodTypes: Array<{
        __typename: 'FoodType'
        id: number
        name: string | null
      }>
    }>
  }
  upsellTypes: Array<{ __typename: 'UpsellType'; id: number; name: string }>
}

export const TruckMenusQueryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'truckMenusQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'truck' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduleUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primaryUpsellTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'position' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'menus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'public' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPrices' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxIncluded' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'catering' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'sort' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'field' },
                                      value: {
                                        kind: 'EnumValue',
                                        value: 'auto'
                                      }
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'direction'
                                      },
                                      value: { kind: 'EnumValue', value: 'asc' }
                                    }
                                  ]
                                }
                              ]
                            }
                          }
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'active' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'medium' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'present' }
                                  }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'upsellType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'position' }
                                  }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'formatted' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cents' }
                                  }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tags' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'highlighted' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageSvgUrl' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' }
                                  }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'itemQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'itemAnswers'
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' }
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'active'
                                          }
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'adjustment'
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'formatted'
                                                }
                                              }
                                            ]
                                          }
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'foodTypes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsellTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown) as DocumentNode

/**
 * __useTruckMenusQuery__
 *
 * To run a query within a React component, call `useTruckMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTruckMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTruckMenusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTruckMenusQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    TruckMenusQuery,
    TruckMenusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<TruckMenusQuery, TruckMenusQueryVariables>(
    TruckMenusQueryDocument,
    options
  )
}
export function useTruckMenusQueryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TruckMenusQuery,
    TruckMenusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    TruckMenusQuery,
    TruckMenusQueryVariables
  >(TruckMenusQueryDocument, options)
}
export type TruckMenusQueryHookResult = ReturnType<typeof useTruckMenusQuery>
export type TruckMenusQueryLazyQueryHookResult = ReturnType<
  typeof useTruckMenusQueryLazyQuery
>
export type TruckMenusQueryQueryResult = Apollo.QueryResult<
  TruckMenusQuery,
  TruckMenusQueryVariables
>
