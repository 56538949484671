import React from 'react'
import Image from 'components/Image'
import { TruckMenusQuery } from 'components/TruckMenus/types/TruckMenusQuery'

type Props = {
  items: NonNullable<TruckMenusQuery>['truck']['menus'][0]['items']
  upsellTypes: {
    id: number
    name: string
  }[]
  primaryUpsellTypes: {
    id: number
    name: string
  }[]
  taxIncluded: boolean
  showPrices: boolean
  formatSectionTitle?: (upsellType: { id: number; name: string }) => string
}

const makeSections = ({
  items,
  upsellTypes
}: {
  items: NonNullable<TruckMenusQuery>['truck']['menus'][0]['items']
  upsellTypes: {
    id: number
    name: string
  }[]
}) => {
  const upsellTypeItems: {
    items: NonNullable<TruckMenusQuery>['truck']['menus'][0]['items']
    upsellType: {
      id: number
      name: string
    }
  }[] = upsellTypes
    .map((upsellType) => ({
      items: items.filter(
        (locationItem) => locationItem.upsellType.id === upsellType.id
      ),
      upsellType
    }))
    .filter((group) => group.items.length > 0)
  return upsellTypeItems
}

const MenuItemsList = ({
  items,
  primaryUpsellTypes,
  upsellTypes,
  taxIncluded,
  showPrices,
  formatSectionTitle = (s) => s.name
}: Props) => {
  const sortedUpsellTypes = [...primaryUpsellTypes]
  upsellTypes.forEach((t1) => {
    if (!sortedUpsellTypes.find((t2) => t2.id === t1.id)) {
      sortedUpsellTypes.push(t1)
    }
  })
  const sections = makeSections({
    items,
    upsellTypes
  })

  const hasImages = items.some((item) => item.image.present)

  return (
    <div className="w-full mx-4 scroll-mt-6" id="menu-items">
      {sections.map((section) => (
        <div
          key={`section-${section.upsellType.id}`}
          className="pt-10 food-section"
          id={`section-${section.upsellType.id}`}
        >
          <div className="pb-4 text-2xl font-bold">
            {formatSectionTitle(section.upsellType)}
          </div>
          <div className="grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-4 lg:gap-x-6">
            {section.items.map((item) => (
              <div
                key={item.id}
                className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
              >
                {hasImages && (
                  <Image
                    alt={item.name}
                    height={360}
                    layout="intrinsic"
                    quality={90}
                    src={item.image.medium}
                    width={360}
                  />
                )}
                <div className="flex flex-1 flex-col space-y-2 p-4">
                  <h3 className="font-medium text-gray-900">{item.name}</h3>
                  <div className="flex gap-1 flex-wrap">
                    {item.tags.map((t) => (
                      <div
                        key={t.id}
                        className="bg-orange-100 rounded-full text-xs text-orange-700 font-light italic px-1.5 py-0.5"
                      >
                        {t.name}
                      </div>
                    ))}
                  </div>
                  <p className="text-sm text-gray-600">{item.description}</p>

                  <div className="flex flex-1 flex-col justify-end">
                    {showPrices && item.price.cents > 0 && (
                      <div className="flex items-center">
                        <p className="text-lg font-medium text-gray-900">
                          {item.price.formatted}
                        </p>
                        {taxIncluded && (
                          <p className="ml-2 text-xs text-gray-400">
                            *Tax Included
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default MenuItemsList
